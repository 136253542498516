#GameID {
    font-weight: 500;
    font-size: calc(12px + 3vmin);
    margin-bottom: 0;
    display: inline;
}

#GameIDInput {
    font-weight: 500;
    color: #f0fff0;
    font-size: calc(12px + 3vmin);
    margin-bottom: 0;
    display: inline;
    position: relative;
    margin-left: 2vmin;
    width: 4em;
    text-align: center;
}

.HomeButton {
  border-radius: 15%;
  border: 2px solid;
  width: 95%;
  padding-top: 10%;
  padding-bottom: 10%;
  text-align: center;
  color: var(--color);
  border-color: var(--color);
  vertical-align: middle;
  font-weight: 900;
  letter-spacing: 1px;
}

.HomeButton:hover, .HomeButton:active {
  color: #313131;
  background-color: var(--color-trans);
  cursor: pointer;
}

.HomeButton:active {
  color: #313131;
  background-color: var(--color);
  cursor: pointer;
}

.HomeButtonSelected {
  border-radius: 15%;
  border: 2px solid;
  width: 95%;
  padding-top: 10%;
  padding-bottom: 10%;
  text-align: center;
  border-color: var(--color);
  vertical-align: middle;
  font-weight: 900;
  letter-spacing: 1px;
  color: #313131;
  background-color: var(--color);
  cursor: pointer;
}

.HomeIcon {
  font-size: calc(60px + 2vw);
}

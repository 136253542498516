.center {
  text-align: center;
}

.body {
  padding-left: 10px;
  padding-right: 10px;
}

.App-logo {
  display: block;
  margin: 0 auto;
  height: 50vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-pulse infinite 2s linear;
  }
}

.background {
  background-color: #313131;
  min-height: 100vh;
  min-width: 100vw;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  overflow-y: scroll;
}

.App {
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  padding: 0;
}

.AppSetting {
  background-color: var(--color-light);
  height: calc(16px + 4vmin);
  font-size: calc(12px + 2vmin);
  text-align: center;
  border: 1px solid var(--color);
  padding-top: 5px;
}

.AppSettingDisabled {
  background-color: var(--color-gray);
  height: calc(16px + 4vmin);
  font-size: calc(12px + 2vmin);
  text-align: center;
  border: 1px solid var(--color);
  padding-top: 5px;
  cursor: default;
}

.AppSetting:hover {
  background-color: var(--color);
  cursor: pointer;
}

.defaultColor {
  color: #61dafb;
}

.nametag {
  font-weight: 400;
  color: #f0fff0;
  font-size: calc(16px + 2vmin);
  position: absolute;
  top: 6px;
}

.scoreboardNametag {
  font-weight: 400;
  color: #f0fff0;
  font-size: calc(12px + 2vmin);
  position: absolute;
  top: 7px;
}

p {
  font-weight: 200;
  color: #f0fff0;
  font-size: calc(8px + 2vmin);
  margin-top: 2px;
  margin-bottom: 2px;
  cursor: default;
}

p.scoreboardHeader {
  font-weight: 400;
  color: #f0fff0;
  margin-top: 2px;
  margin-bottom: 5px;
  font-size: calc(10px + 2vmin);
  cursor: default;
}

p.scoreboard {
  font-weight: 300;
  margin-top: 7px;
  font-size: calc(12px + 2vmin);

}

input, textarea {
  width: calc(100% - 20px);
  resize: none;
  border-style: none none solid none;
  border-color: white;
  border-radius: 0;
  background: none;
  color: #f0fff0;
  font-size: calc(10px + 2vmin);
  font-family: Arial, Helvetica, sans-serif;
}

input:focus, textarea:focus {
  outline: none;
}

#Heading {
    height: calc(20px + 1.7vmin);
    color: #313131;
    border-style: none solid solid solid;
    border: 1px;
    box-shadow: 0 5px 10px black;
    text-align: center;
    padding: calc(5px + 2vmin);
    font-weight: 500;
    font-size: calc(14px + 1.5vmin);
}

@keyframes App-logo-pulse {
  0% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1.2);
  }
}
